.scrollable-div::-webkit-scrollbar {
  width: 6px;
}

.scrollable-div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scrollable-div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  background-color: darkgrey;
}
